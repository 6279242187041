/* istanbul ignore file */ //
// DO NOT EDIT!
// This file was autogenerated by Eddie.
// Changes may cause incorrect behavior and will be lost when the file is regenerated.
//
// Run `npx eddie update workspace` to regenerate.
//
export const APP_NAME = 'frontpage';
export const PACKAGE_NAME = '@ori-appshell/frontpage';
export const TEAM_NAME = 'Presentation';
export const EMOTION_CACHE_KEY = "".concat(APP_NAME, "-emotion-cache-key");
